import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';

export enum BUTTON_THEME {
  BLACK = 'black',
  ORANGE = 'orange',
  BLUE = 'blue',
  RED = 'red',
  BORDER = 'border',
  TRANSPARENT = 'transparent'
}

export interface ButtonProps {
  theme?: BUTTON_THEME,
  padding?: string
  buttonText?: string,
  disabled?: boolean,
  loading?: boolean,
  leadingIcon?: string,
  trailingIcon?: string,
  color?: string,
  iconSize?: string,
  routerLink?: string,
  fontSize?: string
};

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    NgStyle
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ButtonComponent {

  props = input.required<ButtonProps>();
  onClick = output<Event>();

  constructor(
    private router: Router
  ) { }

  onButtonClicked(e: Event) {
    if (this.props().routerLink) {
      this.router.navigate([this.props().routerLink]);
    } else {
      this.onClick.emit(e);
    }
  }

}